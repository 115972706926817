<template>
  <div class="pl-4">
    <v-row>
      <v-col>
        <h4 class="q-mt-sm q-mb-sm">View survey</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h5 class="q-mt-sm q-mb-sm">Details:</h5>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
        <p>
          Customer: {{ survey.customer }}<br />
          Address: {{ survey.address }}
          Customer address:<br />
          <span v-if="survey.customerAddressOne">{{ survey.customerAddressOne }}<br /></span>
          <span v-if="survey.customerAddressTwo">{{ survey.customerAddressTwo }}<br /></span>
          <span v-if="survey.customerAddressThree">{{ survey.customerAddressThree }}<br /></span>
          <span v-if="survey.customerAddressFour">{{ survey.customerAddressFour }}<br /></span>
          <span v-if="survey.customerAddressFive">{{ survey.customerAddressFive }}<br /></span>
          <span v-if="survey.customerPostcode">{{ survey.customerPostcode }}<br /></span>
          Area(s) to be surveyed:
          <span v-if="survey.external">External</span>
          <span v-if="survey.internal">Internal</span>
        </p>
      </v-col>
      <v-col class="col-6">
        Date of survey: {{ survey.prettyDate }}<br />
        Contact: {{ survey.contact }}<br />
        Contact number: {{ survey.contact_number }}<br />
        Booking notes: {{ survey.booking_notes }}<br />
        Vehicle: {{ survey.vehicle }}<br />
        Requested by: {{ survey.requestor }}<br />
        To be surveyed by: {{ survey.surveyor }}
      </v-col>
    </v-row>
    <div v-if="surveySections.length > 0">
      <v-row>
        <v-col>
          <h5 class="mt-1 mb-1">Existing items:</h5>
        </v-col>
      </v-row>
      <v-row :key="index" v-for="(section, index) in surveySections">
        <v-col class="col-6">
          <p>Item #{{ (index + 1) }}</p>
          <p>{{ section.title}}</p>
          <p>{{ section.description }}</p>
        </v-col>
        <div class="col-6">
          <v-row>
            <v-col
                class="col-3"
                :key="findex"
                v-for="(file, findex) in section.files">
              <img class="thumbnail" :src="file.uri" alt="" />
            </v-col>
          </v-row>
        </div>
        <v-col class="col-12">
          <hr />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ViewSurvey',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      survey: {
        address: '',
        booking_notes: '',
        contact: '',
        contact_number: '',
        customer: '',
        customerAddressOne: '',
        customerAddressTwo: '',
        customerAddressThree: '',
        customerAddressFour: '',
        customerAddressFive: '',
        customerPostcode: '',
        external: false,
        id: 0,
        internal: false,
        prettyDate: '',
        requestor: '',
        surveyor: '',
        vehicle: '',
      },
      surveySections: [],
    };
  },
  methods: {
    getSurvey() {
      axios.get(`/siteSurveys/getOne/${this.$route.params.id}.json?token=${this.token}`)
          .then((response) => {
            this.survey = response.data.survey;
          });
    },
    getSurveySections() {
      axios.get(`/siteSurveySections/get/${this.$route.params.id}.json?token=${this.token}`)
          .then((response) => {
            this.surveySections = response.data.sections;
          });
    },
  },
  mounted() {
    this.getSurvey();
    this.getSurveySections();
  },
};
</script>
